@tailwind base;
@tailwind components;
@tailwind utilities;

.fade-in {
  opacity: 0;
  transform: translateY(20px);
  transition:
    opacity 0.6s ease-out,
    transform 0.6s ease-out;
}

.fade-in.visible {
  opacity: 1;
  transform: translateY(0);
}

.parallax {
  font-family: var(--font-sf-pro-display), sans-serif;
  .black-shadow {
    text-shadow: rgb(99 102 241) 2.5px 2.5px;
  }
  .white-shadow {
    text-shadow: #fff 1px 1px;
  }
}

@keyframes slide-in-left {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slide-in-right {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.animate-slide-in-left {
  animation: slide-in-left 2s forwards;
}

.animate-slide-in-right {
  animation: slide-in-right 2s forwards;
}

.shine-effect {
  position: relative;
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  -webkit-text-fill-color: transparent;
  animation: shineMove 5.5s linear infinite;
}

@keyframes shineMove {
  0% {
    background-image: linear-gradient(
      120deg,
      black,
      #ffffff 25%,
      silver 50%,
      #ffffff 75%,
      black
    );
    background-size: 200% 100%;
    background-position: -100% center;
  }
  100% {
    background-image: linear-gradient(
      120deg,
      black,
      #ffffff 25%,
      silver 50%,
      #ffffff 75%,
      black
    );
    background-size: 200% 100%;
    background-position: 200% center;
  }
}

@keyframes fadeInWhite {
  from {
    color: transparent;
  }
  to {
    color: white;
  }
}

.fade-in-on-scroll {
  animation-name: fadeInWhite;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-play-state: paused;
}

.scroll-indicator::before,
.scroll-indicator::after {
  content: "";
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.scroll-indicator::before {
  width: 10px;
  height: 10px;
  border-radius: 10px;
  border: 1px solid #fff;
  animation: dot 3s infinite ease-in-out;
}

.scroll-indicator::after {
  width: 10px;
  height: 10px;
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
  transform: rotate(45deg);
  animation: arrow 3s infinite ease-in-out;
  animation-delay: 0.75s;
  opacity: 0.25;
}

.scroll-indicator > div::before,
.scroll-indicator > div::after {
  content: "";
  display: block;
  margin: 5px auto;
  border-radius: 10px;
  border: 1px solid #fff;
  animation: dot 3s infinite ease-in-out;
}

.scroll-indicator > div::before {
  width: 10px;
  height: 10px;
  animation-delay: 0.25s;
}

.scroll-indicator > div::after {
  width: 10px;
  height: 10px;
  animation-delay: 0.5s;
}

@keyframes dot {
  0% {
    transform: scale(0.75);
    opacity: 0.25;
  }
  25% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0.75);
    opacity: 0.25;
  }
}

@keyframes arrow {
  0% {
    transform: scale(0.75) rotate(45deg);
    opacity: 0.25;
  }
  25% {
    transform: scale(1) rotate(45deg);
    opacity: 1;
  }
  100% {
    transform: scale(0.75) rotate(45deg);
    opacity: 0.25;
  }
}

.animate-dot {
  animation: dot 3s infinite ease-in-out;
}

.animate-arrow {
  animation: arrow 3s infinite ease-in-out;
  animation-delay: 0.75s;
  opacity: 0.25;
}

.glitch {
  position: relative;
  color: white;

  &::before,
  &::after {
    content: attr(data-value);
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    left: 2px;
    clip: rect(44px, 450px, 56px, 0);
  }
}
