@tailwind base;
@tailwind components;
@tailwind utilities;

@import "./effects.scss";
@import "./globals.scss";

html {
  scroll-behavior: smooth;
  height: 100%;
  background: linear-gradient(180deg, #7B5FEB 0%, #6951C8 10%, #5C47B0 20%, #3D2F74 40%, #000000 100%)
    no-repeat fixed;
}

body {
  font-family: "SF Pro Display", sans-serif;
  color: rgb(var(--foreground-rgb));
  font-size: 16px;
  min-height: 100%;
  margin: 0;
  padding: 0;
  // background: linear-gradient(180deg, #222344 0%, #2c2c5a 50%, #3b3b73 100%)
  //   no-repeat fixed;
  background-color: transparent;
  background-size: cover;
}

.fixed-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: linear-gradient(180deg, #7B5FEB 0%, #6951C8 10%, #5C47B0 20%, #3D2F74 40%, #000000 100%);
  z-index: -1;
  pointer-events: none;
}

.customNavbarMenu {
  height: calc(100dvh - var(--navbar-height) - var(--banner-height)) !important;
}

header {
  max-width: 100vw !important;
  height: 64px !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.custom-nav-bar {
  height: calc(100dvh - 64px) !important;
}

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
}
